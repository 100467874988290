import React from "react"

export default function IpoIngress({ ingress }) {
  return (
    <div className="space-y-4 pt-4">
      <h3 className="text-2xl font-bold">Background</h3>
      <div dangerouslySetInnerHTML={{ __html: ingress }} />
    </div>
  )
}
