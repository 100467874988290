import React from "react"
import Button from "../Button"
import Link from "../Link"

export default function IpoProspects({ prospectes }) {
  console.log(prospectes)
  return (
    <div>
      <div className="flex flex-col md:flex-row gap-4">
        {prospectes.map(download => {
          return <Download key={download.title} download={download} />
        })}
      </div>
    </div>
  )
}

function Download({ download }) {
  console.log(download)
  return (
    <div className="">
      <div>
        <Button text={download.title} link={download.file.localFile.url} />
      </div>
    </div>
  )
}
