import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Disclaimer() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 464 }) {
        ipo {
          disclaimer
          disclaimerHeadline
        }
      }
    }
  `)
  const { disclaimer, disclaimerHeadline } = data.wpPage.ipo
  return (
    <div>
      <div className="space-y-4 text-sm">
        <h2 className="text-xl font-bold">
          {disclaimerHeadline || "Important Notice"}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
      </div>
    </div>
  )
}
