import React from "react"

export default function IpoInformationAboutOffer({ informationAboutTheOffer }) {
  return (
    <div className="space-y-8">
      <h3 className="text-2xl font-bold">Information about the offer</h3>
      <div className="space-y-2 divide-y divide-gray-300">
        {informationAboutTheOffer.map(post => {
          return <Post key={post.title} post={post} />
        })}
      </div>
    </div>
  )
}

function Post({ post }) {
  const { title, description } = post

  return (
    <div className="md:grid grid-cols-2  py-4 gap-8 ">
      <div className="font-bold md:font-normal pb-4 md:pb-0">{title}</div>
      <div>{description}</div>
    </div>
  )
}
