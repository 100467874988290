import React from "react"
import { YoutubeHelper } from "../../utils/YoutubeHelper"
export default function IpoYoutube({ youtubeClips }) {
  return (
    <div className="space-y-4 md:space-y-0 grid md:grid-cols-2 md:gap-8">
      {youtubeClips.map(clip => {
        return <Clip key={clip.youtubeUrl} clip={clip} />
      })}
    </div>
  )
}

function Clip({ clip }) {
  const { youtubeUrl, description } = clip

  const youTubeId = YoutubeHelper(youtubeUrl)
  console.log(youTubeId)

  return (
    <div>
      <div class="embed-container">
        <iframe
          src={"https://www.youtube.com/embed//" + youTubeId}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <div>
        <div className="py-8">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  )
}
