import React from "react"
import Layout from "../../components/layout"
import Footer from "../../components/Footer"
import Seo from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import Disclaimer from "../../components/investors/Disclaimer"
import IpoIngress from "../../components/investors/IpoIngress"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import IpoImportantDates from "../../components/investors/IpoImportantDates"
import IpoInformationAboutOffer from "../../components/investors/IpoInformationAboutOffer"
import IpoDownloads from "../../components/investors/IpoDownloads"
import IpoYoutube from "../../components/investors/IpoYoutube"
import IpoProspects from "../../components/investors/IpoProspects"
export default function IpoPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 464 }) {
        id
        title
        ipo {
          ingress
          informationAboutTheOffer {
            description
            title
            fieldGroupName
          }
          importantDates {
            dateinfo
            fieldGroupName
            title
          }
          youtubeClips {
            description
            youtubeUrl
          }
          prospectes {
            title
            file {
              localFile {
                url
              }
            }
          }
          downloads {
            title
            fieldGroupName
            file {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)

  const [disclaimer, setDisclamer] = React.useState(true)
  const [confirm, setConfirm] = React.useState(false)
  const pageTitle = data.wpPage.title

  const handleChange = () => {
    setDisclamer(!disclaimer)
  }
  const handleChange2 = () => {
    setConfirm(true)
  }

  const {
    ingress,
    informationAboutTheOffer,
    importantDates,
    downloads,
    youtubeClips,
    prospectes,
  } = data.wpPage.ipo
  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className=" text-center py-8 md:pt-16 md:pb-8">
        <PageHeader text={pageTitle} />
      </div>
      <div className="max-w-7xl px-4 mx-auto">
        <div>
          {disclaimer && (
            <div>
              <Disclaimer />
              <div className="flex flex-row space-x-4 mt-8">
                <button
                  className="bg-black text-white rounded-full py-2 px-3 text-sm"
                  onClick={handleChange}
                >
                  Yes, I accept
                </button>
                <button
                  onClick={handleChange2}
                  className="bg-black text-white rounded-full py-2 px-3 text-sm"
                >
                  No, I don't accept
                </button>
              </div>
              <div className="text-sm pt-4">
                {confirm &&
                  "Du kan inte nå innehållet utan att bekräfta den nödvändiga informationen."}
              </div>
            </div>
          )}
        </div>
        {!disclaimer && (
          <div className="w-full space-y-8">
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-8">
                <IpoIngress ingress={ingress} />
                <IpoImportantDates importantDates={importantDates} />
                <IpoDownloads downloads={downloads} />
              </div>
              <div className="w-full space-y-8">
                <div className="">
                  <StaticImage
                    src="../../images/prospect-front.jpg"
                    layout="constrained"
                    alt="Newbury Pharma"
                  />
                </div>
                <IpoProspects prospectes={prospectes} />
              </div>
            </div>
            <div>
              <IpoInformationAboutOffer
                informationAboutTheOffer={informationAboutTheOffer}
              />
            </div>
            <div className="border-b-4 border-secondary mb-8"></div>
            <div className="">
              <IpoYoutube youtubeClips={youtubeClips} />
            </div>
          </div>
        )}
      </div>
      <div className="pt-20">
        <Footer />
      </div>
    </Layout>
  )
}
